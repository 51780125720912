<template>
	<div>
		<video :src="src" class="video" controls></video>
	</div>
</template>

<script>
	export default {
		name: "videO",
		data() {
			return {
				src: ''
			};
		},
		// onLoad(options) {
		// 	console.log(options)
		// 	this.src = options.videourl
		// },
		mounted() {
			this.src = JSON.parse(this.$route.query.videourl)
		},
		methods: {

		},
	}


</script>

<style>
	.video {
	            width: 100%;
	            margin-top: 10px;
	        }
</style>