<template>

  <div class="app">

    <router-view></router-view>

  </div>

</template>

<script>

  // import Home from '../src/pages/home.vue'

  export default {

    name:'app',

    data(){

      return {

      }

    },

    methods:{

    },

    components:{

      // Home

    }

  }

</script>

<style>
*{
		margin: 0;
		padding: 0;
	}
</style>