<template>
  <div style="">
    <div style="overflow-x: auto">
      <div class="barr">
        <div class="sele">
          <el-select
            v-model="values"
            @change="changeSelect"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="padding-bottom: 0px; height: 1vh"></div>
        <div class="posi1">进口端</div>
        <div class="posi2">出口端</div>
        <div class="bar-1" style="position: relative">
          <bar></bar>
        </div>
        <div class="bar-1" style="margin-top: 3vh; position: relative">
          <bar2></bar2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bar from "../components/earth.vue";
import bar2 from "../components/earth0.vue";
// import bar3 from "../components/earth2.vue";

export default {
  name: "homeA",
  props: [],
  components: {
    bar,
    bar2,
    // bar3,
  },

  data() {
    return {
      lineData1: [],
      lineData2: [],
      lineData3: [],
      lineData4: [],
      lineData5: [],
      lineData6: [],
      lineData7: [],
      lineData8: [],
      long: 0,
      options: [
        {
          value: "1",
          label: "左线-进口端",
        },
        {
          value: "2",
          label: "右线-进口端",
        },
        {
          value: "3",
          label: "左线-出口端",
        },
        {
          value: "4",
          label: "右线-出口端",
        },
      ],
      values: "",
    };
  },

  mounted() {
    // alert(this.$route.query.name)
    this.getSd();
  },
  methods: {
    changeSelect(e) {
      console.log(e);
      // sessionStorage.setItem("jdgl", e);
      this.values = e;
      if (e || e != "") {
        this.$router.push({
          path: `/homeback/${e}`,
        });
        // this.$router.push("/information/YbgcJdjk-data/YbgcJdjk/" + e);
      }
    },
    getSd() {
      this.lineData1 = [];
      this.lineData2 = [];
      var arr = [];
      var brr = [];
      var that = this;
      this.$axios
        .get(
          "https://blzhsdwb.qxweb.cloud/api/information/YbgcSchedule/listNoAuth?pageNum=1&pageSize=10"
        )
        .then(function (response) {
          for (var i = 0; i < response.data.rows.length; i++) {
            if (response.data.rows[i].scheduleType == "左线") {
              arr.push(response.data.rows[i]);
            }
            if (response.data.rows[i].scheduleType == "右线") {
              brr.push(response.data.rows[i]);
            }
          }
          that.lineData1 = arr;
          that.lineData2 = brr;
          that.long = response.data.rows[0].tunnelWidth;
          // that.lineData1 = response.data.data.result0;
          // that.lineData2 = response.data.data.result1;
        })
        .catch(function (error) {
          console.log(error);
        });
      // 	var that =this
      // 	that.$axios({
      //     url: "/api/apisystem/YbgcGdsc/monitoring?type=1&tunnelId=1",
      //     method: "get",
      //     success: (response) => {

      // 		console.log(response.data)
      // 		// that.long = response.data.width;
      // 		// that.lineData1 = response.data.result0;
      // 		// that.lineData2 = response.data.result1;
      // 		// that.lineData3 = response.data.result2;
      // 		// that.lineData4 = response.data.result3;
      // 		// that.lineData5 = response.data.result4;
      // 		// that.lineData6 = response.data.result5;
      // 		// that.lineData7 = response.data.result6;
      // 		// that.lineData8 = response.data.result7;
      //     },
      //     error: () => {},
      //   });
    },
  },
  updated() {},
};
</script>

<style scope>
.kwjc {
  position: absolute;
  top: 33%;
  left: 11%;
  font-size: 2.2vh;
  color: #555;
}
.kwjc2 {
  position: absolute;
  top: 33%;
  right: 11%;
  font-size: 2.2vh;
  color: #555;
}
.title_back {
  background: url("../assets/images/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -5%;
  left: 14%;
}
.title_back p {
  height: 7.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.title_back2 {
  background: url("../assets/images/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -5%;
  right: 14%;
}
.title_back2 p {
  height: 7.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.titles {
  font-size: 22px;
  padding: 5px 10px;
  background-color: rgba(64, 149, 229, 0.13);
  margin: 10px 0;
}
.box {
  position: relative;
  width: 70%;
  border: 1px solid #555;
  padding: 10px 50px;
  margin: 0 auto 20px auto;
}
.posi {
}
.bar-1 {
  width: 70vw;
  margin: 0 auto;
  /* height: 20vh; */
}
.barr {
  position: relative;
  /* background: url(../../../assets/images/bar.png) no-repeat; */
  background-size: 80% 100%;
  background-position: center;
  /* width:  100vw; */
  padding-top: 2vh;
  /* padding-bottom: 30px; */
}
.center {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.center2 {
  text-align: center;
  font-size: 15px;
  margin-left: 20px;
}
.posi1 {
  position: absolute;
  font-weight: 600;
  font-size: 24px;
  width: 46px;
  background-color: rgba(80, 109, 148, 1);
  text-align: center;
  padding: 20px 0;
  color: #fff;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
}
.posi2 {
  position: absolute;
  font-weight: 600;
  font-size: 24px;
  width: 46px;
  background-color: rgba(80, 109, 148, 1);
  text-align: center;
  padding: 20px 0;
  color: #fff;
  top: 50%;
  right: 5%;
  transform: translate(0, -50%);
}
.sele {
  width: 120px;
    position: absolute;
    top: 5%;
    left: 1%;
}
</style>

<style>
.input-with-select .el-select .el-input {
  width: 130px;
}
.input-with-select .el-select.selects .el-input {
  width: 130px;
  border-left: 1px solid #ccc;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-input-group__prepend {
  width: 260px;
}
.el-input-group__prepend .el-select.selects {
  margin: -7px 0;
  width: 110px !important;
}
.el-input-group__prepend .el-select {
  width: 150px !important;
}
select[readonly] {
  background: #eee;
  cursor: no-drop;
}
</style>
