<template>
  <div style="width: 80%; height: 100%; margin: 0 auto">
    <div
      style="
        font-size: 20px;
        text-align: center;
        margin-top: 0px;
        cursor: pointer;
      "
    >
      右线
    </div>
    <div v-if="lineData1.length > 0" class="title_back">
      <p>掌子面桩号：{{ lineData1[0].importZzmNumber }}</p>
    </div>
    <div v-if="lineData1.length > 0" class="title_back2">
      <p>掌子面桩号：{{ lineData1[0].exportZzmNumber }}</p>
    </div>
    <!-- <div></div> -->
    <div style="width: 100%; height: 100%" ref="chart"></div>
    <!-- <div
		  style="
			width: 64.2%;
			height: 10.1%;
			background-color: rgba(43, 81, 137, 0.3);
			position: absolute;
			top: 18.7%;
			left: 17.98%;
			z-index: -1;
		  "
		></div> -->
    <div
      style="text-align: center; width: 100%; font-size: 20px; margin-top: -5%"
    >
      <img
        style="
          width: 5.4vw;
          height: 6vh;
          margin-right: 1vw;
          vertical-align: middle;
        "
        src="@/assets/images/leftup.png"
        alt=""
      />
      剩余开挖长度：<span style="color: #00ccff; font-size: 28px">{{
        sylongs
      }}</span>
      米
      <img
        style="
          width: 5.4vw;
          height: 6vh;
          margin-left: 1vw;
          vertical-align: middle;
        "
        src="@/assets/images/rightup.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
// import "echarts-liquidfill/src/liquidFill.js"; //在这里引入

// import { getEnter } from "@/api/home";
//   import {
// 	listYbgcSchedule,
// 	getYbgcSchedule,
// 	addYbgcSchedule,
// 	updateYbgcSchedule,
//   } from "@/api/information/YbgcSchedule";
//   import { listYbgcTunnel } from "@/api/information/YbgcTunnel";
const echarts = require("echarts");
export default {
  name: "yqjcEcharts",
  data() {
    return {
      myChart: null,
      PersonInfo: {},
      xAxis: [],
      yAxis: [],
      longs: 0,
      long: 0,
      sylongs: 0,
      option: {},
      title: "",
      YbgcScheduleList: [],
      YbgcTunnelList: [],
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      addShow: true,
      disabled: false,
      disabled2: false,
      id: "",
      useRole: false,
      lineData1: [],
      lineData2: [],
    };
  },
  props: {
    // lineData: {
    //   type: Array,
    //   default: [],
    // },
    // parmas1: {
    //   type: String,
    //   default: "",
    // },
  },
  watch: {
    // // parmas1(n, m) {
    // //   console.log(n, m);
    // //   if (n != "") {
    // //     var obj = JSON.parse(n);
    // //     if (obj.deptid) {
    // //       console.log(this.option.series);
    // //       var str = obj.type + obj.gdscStakemark;
    // //       if (this.option.series.length > 0) {
    // //         var indexOf4 = this.option.series.findIndex(
    // //           (item) => item.name === str
    // //         );
    // //         if (indexOf4 == "-1") {
    // //           this.$message({
    // //             type: "warning",
    // //             message: "暂无数据",
    // //           });
    // //         } else {
    // //           this.posiWz(indexOf4);
    // //         }
    // //       } else {
    // //         this.$message({
    // //           type: "warning",
    // //           message: "暂无数据",
    // //         });
    // //       }
    // //     } else {
    // //       for (var i in this.option.series) {
    // //         if (this.option.series[i].name == str) {
    // //           this.myChart.dispatchAction({
    // //             type: "downplay",
    // //             seriesIndex: i,
    // //             dataIndex: 0,
    // //           });
    // //         }
    // //       }
    // //     }
    // //   }
    // // },
    // lineData(n, m) {
    //   if (n) {
    //     console.log(n);
    //     this.sylongs =
    //       JSON.parse(n[0].tunnelWidth) -
    //       n[0].value -
    //       (n.length == 2 ? n[1].value : 0);
    //     var arr = [];
    //     var arr2 = [];
    //     for (var i = 0; i < n.length; i++) {
    //       if (n[i].gdscType == "进口") {
    //         this.longs = JSON.parse(n[0].tunnelWidth);
    //         n[i].data = [];
    //         n[i].data.push(n[i].value);
    //         arr.push(n[i]);
    //       }
    //       if (n[i].gdscType == "出口") {
    //         n[i].data = [];
    //         n[i].data.push(n[i].value);
    //         arr2.push(n[i]);
    //       }
    //     }
    //     this.initCharts(arr, arr2);
    //   }
    // },
  },
  created() {
    // this.getList();
    this.listYbgcTunnel();
  },
  methods: {
    listYbgcTunnel() {
      var that = this;
      this.$axios
        .get(
          "https://blzhsdwb.qxweb.cloud/api/information/YbgcTunnel/listNoAuth?pageNum=1&pageSize=10000"
        )
        .then(function (response) {
          that.YbgcTunnelList = response.data.rows;
          that.form.tunnelId = response.data.rows[0].tunnelId;
          that.long = response.data.rows[0].rightLength;
          that.getList();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getEnter(item) {
      var n = JSON.parse(item);
      console.log(n);

      this.sylongs =
        JSON.parse(n[0].rightLength) -
        n[0].value -
        (n.length == 2 ? n[1].value : 0);
      var arr = [];
      var arr2 = [];
      for (var i = 0; i < n.length; i++) {
        if (n[i].gdscType == "进口") {
          this.longs = JSON.parse(n[0].rightLength);
          n[i].data = [];
          n[i].data.push(n[i].value);
          arr.push(n[i]);
        }
        if (n[i].gdscType == "出口") {
          n[i].data = [];
          n[i].data.push(n[i].value);
          arr2.push(n[i]);
        }
      }
      this.initCharts(arr, arr2);
    },
    initCharts(arr, arr2) {
      this.myChart = this.$echarts.init(this.$refs.chart);
      var obj = {
        ydata: [""],
        jadata: [""],
        legend: [],
        barData: arr,
      };

      function sum(arr) {
        var s = 0;
        arr.forEach(function (val) {
          s += JSON.parse(val.data[0]);
        }, 0);

        return s;
      }

      let serData = [];
      if (obj.barData.length > 0) {
        obj.barData.forEach((item, index) => {
          serData.push({
            showBackground: false,
            backgroundStyle: {
              color: "rgba(43, 81, 137, 0.3)",
            },
            id: index,
            name: item.label,
            label: {
              show: true,
              offset: [15, -40], // 将标签上移20个像素
              color: "#555",
              fontWeight: 500,
              position: "left",
              align: "left",
              fontSize: 18,
              formatter: function (params) {
                console.log(params);
                return "开挖进尺：" + params.value + "米";
              },
            },
            xAxisIndex: 0,
            yAxisIndex: 0,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "#2A7BCC",
                },
                {
                  offset: 1,
                  color: "#5DCAF9",
                },
              ]),
              borderWidth: 2,
              borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "#2A7BCC",
                },
                {
                  offset: 1,
                  color: "#5DCAF9",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            type: "bar",
            stack: "one",
            realtimeSort: false,
            barWidth: 50,
            data: item.data,
            z: 1,
          });
        });
      } else {
        serData.push({
          name: "",
          // //id: '',
          xAxisIndex: 0,
          label: {
            show: true,
            offset: [15, -40], // 将标签上移20个像素
            color: "#555",
            fontWeight: 500,
            position: "left",
            align: "left",
            fontSize: 18,
            formatter: function (params) {
              console.log(params);
              return "开挖进尺：" + params.value + "米";
            },
          },
          yAxisIndex: 0,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2A7BCC",
              },
              {
                offset: 1,
                color: "#5DCAF9",
              },
            ]),
            borderWidth: 2,
            borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2A7BCC",
              },
              {
                offset: 1,
                color: "#5DCAF9",
              },
            ]),
          },
          emphasis: {
            focus: "series",
          },
          type: "bar",
          stack: "one",
          realtimeSort: false,
          barWidth: 50,
          data: [0],
          showBackground: false,
          backgroundStyle: {
            color: "rgba(43, 81, 137, 0.3)",
          },
          z: 1,
        });
      }
      if (arr2.length > 0) {
        arr2.forEach((item, index) => {
          serData.push({
            z: 1,
            id: index + "s",
            name: item.label,
            xAxisIndex: 1,
            yAxisIndex: 1,
            label: {
              show: true,
              offset: [-20, -40], // 将标签上移20个像素
              color: "#555",
              fontWeight: 500,
              position: "right",
              align: "right",
              fontSize: 18,
              formatter: function (params) {
                console.log(params);
                return "开挖进尺：" + params.value + "米";
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "#2A7BCC",
                },
                {
                  offset: 1,
                  color: "#5DCAF9",
                },
              ]),
              borderWidth: 2,
              borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "#2A7BCC",
                },
                {
                  offset: 1,
                  color: "#5DCAF9",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            type: "bar",
            stack: "right",
            realtimeSort: false,
            barWidth: 50,
            data: item.data,
            showBackground: false,
            backgroundStyle: {
              color: "rgba(43, 81, 137, 0.3)",
            },
          });
        });
      } else {
        serData.push({
          name: "",
          // //id: '',
          xAxisIndex: 1,
          label: {
            show: true,
            offset: [-20, -40], // 将标签上移20个像素
            color: "#555",
            fontWeight: 500,
            position: "right",
            align: "right",
            fontSize: 18,
            formatter: function (params) {
              console.log(params);
              return "开挖进尺：" + params.value + "米";
            },
          },
          yAxisIndex: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2A7BCC",
              },
              {
                offset: 1,
                color: "#5DCAF9",
              },
            ]),
            borderWidth: 2,
            borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2A7BCC",
              },
              {
                offset: 1,
                color: "#5DCAF9",
              },
            ]),
          },
          emphasis: {
            focus: "series",
          },
          type: "bar",
          stack: "right",
          realtimeSort: false,
          barWidth: 50,
          data: [0],
          showBackground: false,
          backgroundStyle: {
            color: "rgba(43, 81, 137, 0.3)",
          },
          z: 1,
        });
      }
      if (serData.length == 2) {
        serData.push({
          id: "index",
          name: "item.label",
          label: {
            show: false,
            offset: [15, -40], // 将标签上移20个像素
            color: "#555",
            fontWeight: 500,
            position: "left",
            align: "left",
            fontSize: 20,
          },
          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2A7BCC",
              },
              {
                offset: 1,
                color: "#5DCAF9",
              },
            ]),
            borderWidth: 2,
            borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2A7BCC",
              },
              {
                offset: 1,
                color: "#5DCAF9",
              },
            ]),
          },
          emphasis: {
            focus: "series",
          },
          type: "bar",
          stack: "two",
          realtimeSort: false,
          barWidth: 50,
          data: [0],
          showBackground: true,
          backgroundStyle: {
            color: "rgba(43, 81, 137, 0.3)",
          },
          z: 0,
        });
      }
      console.log(serData);

      // obj.barData.forEach((item) => {

      // });
      if (this.longs == 0) {
        var a = 0;
        var b = 0;
        var c = 0;
        var d = 0;
        var e = 0;
      } else {
        var a = this.longs;
        var b = obj.barData[0].value;
        var c = arr2[0].value;
        var d = (b / a) * 100;
        var e = (c / a) * 100;
      }
      console.log(a, b, c, d, e);
      var f = 0;
      if (d < 10) {
        f = d;
      } else if (d > 90) {
        f = d - 20;
      } else {
        f = d - 10;
      }
      var g = 0;
      if (e < 10) {
        g = e;
      } else if (e > 90) {
        g = e - 20;
      } else {
        g = e - 10;
      }
      console.log(f, g);
      //  var f = d<10?d:(d>90?d-20:d-10)
      this.option = {
        //你的代码
        // backgroundColor: "#123756",
        tooltip: {
          // backgroundColor: "rbga(0,0,0,3)",
          trigger: "item",
          position: "right",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let str =
              '<div style="text-align: left;color:#8994a5;">' +
              "右线" +
              '</div><div style="text-align: left;line-height: 25px;">';
            str +=
              params.marker +
              params.seriesName +
              " : " +
              params.value +
              " 米<br/>";
            str += "</div>";
            return str;
          },
        },

        //图标离容器的距离
        grid: [
          {
            show: false,
            left: "0%",
            bottom: "10%",
            top: "10%",
            //是否包含坐标轴
            containLabel: false,
            width: JSON.parse(d) + "%",
          },

          {
            show: false,
            right: "0%",
            bottom: "10%",
            top: "10%",
            //是否包含坐标轴
            containLabel: false,
            width: JSON.parse(e) + "%",
          },
          {
            show: false,
            right: "0%",
            bottom: "10%",
            top: "10%",
            //是否包含坐标轴
            containLabel: false,
            width: 100 + "%",
          },
        ],
        //x坐标轴
        xAxis: [
          {
            gridIndex: 0,
            type: "value",
            inverse: false,
            triggerEvent: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.3",
              },
            },
            boundaryGap: false,
            axisLine: {
              show: false,
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#5d85a0",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
              color: "#fff",
              fontSize: 16,
            },
            max: b,
          },
          {
            gridIndex: 1,

            type: "value",
            inverse: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.3",
              },
            },
            boundaryGap: false,
            axisLine: {
              show: false,
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#5d85a0",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
              color: "#fff",
              fontSize: 16,
            },
            max: c,
          },
          {
            gridIndex: 2,
            type: "value",
            inverse: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.3",
              },
            },
            boundaryGap: false,
            axisLine: {
              show: false,
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#5d85a0",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
              color: "#fff",
              fontSize: 16,
            },
            max: a,
          },
        ],
        //y坐标轴
        yAxis: [
          {
            gridIndex: 0,
            type: "category",
            name: "",
            data: "",
            position: "top",
            inverse: true,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#5d85a0",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
          },

          {
            gridIndex: 1,

            type: "category",
            name: "",
            data: "",
            inverse: false,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#5d85a0",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
          },
          {
            gridIndex: 2,
            type: "category",
            name: "",
            data: "",
            position: "top",
            inverse: true,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#5d85a0",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
          },
        ],
        // 数据展示区域
        series: serData,
      };

      this.myChart.setOption(this.option);
      // this.myChart=myChart
      // this.posiWz(2)
      // var that = this;
      // that.myChart.on("click", function (params) {
      //   if (that.useRole) {
      // 	that.handleUpdate();
      //   }
      // });

      // }, 1000);
    },
    posiWz(n) {
      console.log(n);
      // setInterval(function () {
      // var dataLen = option.series.length;
      // 取消之前高亮的图形
      this.myChart.dispatchAction({
        type: "downplay",
        seriesIndex: n,
        dataIndex: 0,
      });
      // app.currentIndex = (app.currentIndex + 1) % dataLen;
      //console.log(app.currentIndex);
      // 高亮当前图形
      this.myChart.dispatchAction({
        type: "highlight",
        seriesIndex: n,
        dataIndex: 0,
      });
      // 显示 tooltip
      this.myChart.dispatchAction({
        type: "showTip",
        seriesIndex: n,
        dataIndex: 0,
      });
    },

    getList() {
      var that = this;
      that.lineData1 = [];
      this.$axios
        .get(
          "https://blzhsdwb.qxweb.cloud/api/information/YbgcSchedule/listNoAuth?pageNum=1&pageSize=10"
        )
        .then(function (response) {
          if (response.data.rows.length > 0) {
            if (response.data.rows.length == 1) {
              if (response.data.rows[0].scheduleType == "右线") {
                console.log(response.data.rows[0].scheduleType);
                that.disabled = true;
                that.disabled2 = false;
                that.addShow = false;
              } else {
                that.disabled = false;
                that.disabled2 = true;
                that.addShow = true;
              }
            }

            if (response.data.rows.length >= 2) {
              if (response.data.rows.length == 2) {
                that.disabled = true;
                that.disabled2 = true;
                that.addShow = false;
              }
              that.addShow = false;
            }
          } else {
            that.disabled = false;
            that.disabled2 = false;
            that.addShow = true;
          }
          that.YbgcScheduleList = response.data.rows;
          var arr = [];
          if (that.YbgcScheduleList.length > 0) {
            for (var i = 0; i < that.YbgcScheduleList.length; i++) {
              if (that.YbgcScheduleList[i].scheduleType == "右线") {
                that.id = that.YbgcScheduleList[i].id;
                arr.push({
                  rightLength: that.long,
                  gdscType: "进口",
                  value: response.data.rows[i].importExcavationProgress,
                  label: response.data.rows[i].importZzmNumber,
                });
                arr.push({
                  rightLength: that.long,
                  gdscType: "出口",
                  value: response.data.rows[i].exportExcavationProgress,
                  label: response.data.rows[i].exportZzmNumber,
                });
                that.lineData1.push(response.data.rows[i]);
              }
            }
            that.getEnter(JSON.stringify(arr));
          }
          that.total = response.total;
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        tunnelId: null,
        scheduleType: null,
        importExcavationProgress: null,
        importZzmNumber: null,
        exportExcavationProgress: null,
        exportZzmNumber: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        delFlag: null,
      };
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd(item) {
      this.reset();
      this.form.scheduleType = item;
      this.listYbgcTunnel();
      // this.getList2();
      this.open = true;
      this.title = "添加 隧道进度";
    },
    /** 修改按钮操作 */
    handleUpdate() {
      this.reset();
      const id = this.id;
      var that = this;
      this.$axios
        .get("https://blzhsdwb.qxweb.cloud/api/information/YbgcSchedule/" + id)
        .then(function (response) {
          that.form = response.data;
          that.open = true;
          that.title = "修改 隧道进度";
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /** 提交按钮 */
    submitForm() {
      console.log(this.long);
      if (
        JSON.parse(this.form.importExcavationProgress) +
          JSON.parse(this.form.exportExcavationProgress) >
        this.long
      ) {
        this.$modal.msgWarning("进度与出口开挖进度的总和不能超过隧道长度！");
        return false;
      }
      if (JSON.parse(this.form.importExcavationProgress) < 0) {
        this.$modal.msgWarning("进口开挖进度不能为负数！");
        return false;
      }
      if (JSON.parse(this.form.exportExcavationProgress) < 0) {
        this.$modal.msgWarning("出口开挖进度不能为负数！");
        return false;
      }
      var that = this;
      that.$refs["form"].validate((valid) => {
        if (valid) {
          if (that.form.id != null) {
            that.$axios
              .put(
                "https://blzhsdwb.qxweb.cloud/api/information/YbgcSchedule",
                that.form
              )
              .then(function (response) {
                that.$modal.msgSuccess("修改成功");
                that.open = false;
                that.getList();
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            that.$axios
              .post(
                "https://blzhsdwb.qxweb.cloud/api/information/YbgcSchedule",
                that.form
              )
              .then(function (response) {
                that.$modal.msgSuccess("新增成功");
                that.open = false;
                that.getList();
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      });
    },
  },
  mounted() {
    // this.getEnter();
    // this.initCharts();
  },
};
</script>

<style scoped>
.title_back {
  background: url("../assets/images/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -5%;
  left: 14%;
}
.title_back p {
  height: 7.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.title_back2 {
  background: url("../assets/images/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -5%;
  right: 14%;
}
.title_back2 p {
  height: 7.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
</style>
