import Vue from "vue";
// 引入路由第三方包
import VueRouter from "vue-router";
// 在vue项目中使用vue-router进行管理
Vue.use(VueRouter);

// 引入组件
import Home from "@/pages/home.vue";
import Video from "@/pages/video.vue";
import Homeback from "@/pages/homeback.vue";

// 创建路由实例，进行路由配置
const router = new VueRouter({
  // 1.通过routes添加路由配置，可以添加多个路由配置，routes是一个数组
  routes: [
    // 2.添加单个路由配置，每个路由配置是一个对象，常用的配置项有：
    // path:路由路径
    // component:组件实例，引入组件就会有组件实例
    {
      path: "/",
      component: Video,
    },
    {
      path: "/video",
      name:'video',
      component: Video,
    },
    {
      path: "/home",
      name:'home',
      component: Home,
    },
    {
      path: "/homeback/:id",
      name:'homeback',
      // component: () => import("@/pages/homeback.vue"),

      // 按需加载，只有在访问该路由时才会加载组件，提高首屏加载速度

      // 注意：按需加载时，需要使用() => import()语法，不能使用import语句

      // 原因：import语句会导致整个组件被加载，而() => import()只会加载组件的某个方法


      // 解决：使用() => import()语法，只加载组件的某个方法，可以有效提高首屏加载速度


      // 注意：使用() => import()语法时，不能使用export default导出组件，只能使用export导出组件的方法


      // 解决：使用export导出组件的方法，然后在() => import()中导入组件的方法

      
      component: Homeback,
    },
    
  ],
});

// 将路由实例导出
export default router;
