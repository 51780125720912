<template>
  <div style="width: 100%; height: 85vh; position: relative">
    <div class="title-name">
      观洞隧道：
      <el-dropdown @command="handleCommand" trigger="click">
        <span class="el-dropdown-link">
          {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">左线-进口端</el-dropdown-item>
          <el-dropdown-item command="2">右线-进口端</el-dropdown-item>
          <el-dropdown-item command="3">左线-出口端</el-dropdown-item>
          <el-dropdown-item command="4">右线-出口端</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- <div class="btnEdit">
      <el-button
        type="warning"
        plain
        icon="el-icon-edit"
        v-if="useRole"
        size="mini"
        @click="handleUpdate"
        v-hasPermi="['information:YbgcSchedule:edit']"
        >编辑</el-button
      >
    </div> -->
    <div style="padding-top: 30vh" v-if="YbgcJdjkId == 4">
      <div class="back" style="position: relative" >
        <div class="line">
          <p>仰拱至先行导坑距离：{{ form.rightYgdk }} 米</p>
        </div>
        <div class="line2">
          <p>二衬至先行导坑距离：{{ form.rightEcdk }}米</p>
        </div>
        <div class="title_one">
          <p>掌子面桩号：{{ form.exportZzmNumber }}</p>
        </div>
        <div class="title_two">
          <p>仰拱桩号：{{ form.rightYgzh }}</p>
        </div>
        <div class="title_three">
          <p>二衬桩号：{{ form.rightEczh }}</p>
        </div>
      </div>
    </div>

    <div style="padding-top: 38vh" v-if="YbgcJdjkId == 3">
      <div class="back2" style="position: relative">
        <div class="line">
          <p>仰拱至先行导坑距离：{{ form.rightYgdk }} 米</p>
        </div>
        <div class="line2">
          <p>二衬至先行导坑距离：{{ form.rightEcdk }}米</p>
        </div>
        <div class="title_one">
          <p>掌子面桩号：{{ form.exportZzmNumber }}</p>
        </div>
        <div class="title_two">
          <p>仰拱桩号：{{ form.rightYgzh }}</p>
        </div>
        <div class="title_three">
          <p>二衬桩号：{{ form.rightEczh }}</p>
        </div>
      </div>
    </div>
    <div style="padding-top: 38vh" v-if="YbgcJdjkId == 1">
      <div class="back3" style="position: relative">
        <div class="line">
          <p>仰拱至先行导坑距离：{{ form.leftYgdk }} 米</p>
        </div>
        <div class="line2">
          <p>二衬至先行导坑距离：{{ form.leftEcdk }}米</p>
        </div>
        <div class="title_one">
          <p>二衬桩号：{{ form.leftEczh }}</p>
        </div>
        <div class="title_two">
          <p>仰拱桩号：{{ form.leftYgzh }}</p>
        </div>
        <div class="title_three">
          <p>掌子面桩号：{{ form.importZzmNumber }}</p>
        </div>
      </div>
    </div>
    <div style="padding-top: 38vh" v-if="YbgcJdjkId == 2">
      <div class="back4" style="position: relative">
        <div class="line">
          <p>仰拱至先行导坑距离：{{ form.leftYgdk }} 米</p>
        </div>
        <div class="line2">
          <p>二衬至先行导坑距离：{{ form.leftEcdk }}米</p>
        </div>
        <div class="title_one">
          <p>二衬桩号：{{ form.leftEczh }}</p>
        </div>
        <div class="title_two">
          <p>仰拱桩号：{{ form.leftYgzh }}</p>
        </div>
        <div class="title_three">
          <p>掌子面桩号：{{ form.importZzmNumber }}</p>
        </div>
      </div>
    </div>
    <!-- 添加或修改 隧道进度对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form2" :model="form2" :rules="rules" label-width="150px">
        <!-- <el-form-item label="隧道管理ID" prop="tunnelId">
          <el-input v-model="form.tunnelId" placeholder="请输入隧道管理ID" />
        </el-form-item> -->
        <el-row :gutter="10" v-if="YbgcJdjkId == 1 || YbgcJdjkId == 2">
          <el-col :span="24">
            <el-form-item label="进口掌子面桩号" prop="importZzmNumber">
              <el-input
                v-model="form2.importZzmNumber"
                placeholder="请输入进口掌子面桩号"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="进口开挖进度" prop="importExcavationProgress">
              <el-input
                v-model="form2.importExcavationProgress"
                type="number"
                :min="0"
                placeholder="请输入进口开挖进度"
              />
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row :gutter="10" v-if="YbgcJdjkId == 3 || YbgcJdjkId == 4">
          <el-col :span="24">
            <el-form-item label="出口掌子面桩号" prop="exportZzmNumber">
              <el-input
                v-model="form2.exportZzmNumber"
                placeholder="请输入出口掌子面桩号"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="出口开挖进度" prop="exportExcavationProgress">
              <el-input
                v-model="form2.exportExcavationProgress"
                type="number"
                min="0"
                placeholder="请输入出口开挖进度"
              />
            </el-form-item>
          </el-col> -->
        </el-row>

        <!-- 1+ -->

        <el-row :gutter="10" v-if="YbgcJdjkId == 1 || YbgcJdjkId == 2">
          <el-col :span="24">
            <el-form-item label="进口仰拱桩号" prop="leftYgzh">
              <el-input
                v-model="form2.leftYgzh"
                placeholder="请输入进口仰拱桩号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="YbgcJdjkId == 1 || YbgcJdjkId == 2">
          <el-col :span="24">
            <el-form-item label="进口二衬桩号" prop="leftEczh">
              <el-input
                v-model="form2.leftEczh"
                placeholder="请输入进口二衬桩号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="YbgcJdjkId == 1 || YbgcJdjkId == 2">
          <el-col :span="24">
            <el-form-item label="仰拱至先行导坑距离" prop="leftYgdk">
              <el-input
                v-model="form2.leftYgdk"
                type="number"
                :min="0"
                placeholder="请输入仰拱至先行导坑距离"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10" v-if="YbgcJdjkId == 3 || YbgcJdjkId == 4">
          <el-col :span="24">
            <el-form-item label="出口仰拱桩号" prop="rightYgzh">
              <el-input
                v-model="form2.rightYgzh"
                placeholder="请输入出口仰拱桩号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="YbgcJdjkId == 3 || YbgcJdjkId == 4">
          <el-col :span="24">
            <el-form-item label="出口二衬桩号" prop="rightEczh">
              <el-input
                v-model="form2.rightEczh"
                placeholder="请输入出口二衬拱桩号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="YbgcJdjkId == 3 || YbgcJdjkId == 4">
          <el-col :span="24">
            <el-form-item label="仰拱至先行导坑距离" prop="rightYgdk">
              <el-input
                v-model="form2.rightYgdk"
                type="number"
                min="0"
                placeholder="请输入仰拱至先行导坑距离"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10" v-if="YbgcJdjkId == 1 || YbgcJdjkId == 2">
          <el-col :span="24">
            <el-form-item label="二衬至先行导坑距离" prop="leftEcdk">
              <el-input
                v-model="form2.leftEcdk"
                type="number"
                :min="0"
                placeholder="请输入二衬至先行导坑距离"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10" v-if="YbgcJdjkId == 3 || YbgcJdjkId == 4">
          <el-col :span="24">
            <el-form-item label="二衬至先行导坑距离" prop="rightEcdk">
              <el-input
                v-model="form2.rightEcdk"
                type="number"
                min="0"
                placeholder="请输入二衬至先行导坑距离"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { listYbgcProjectAll } from "@/api/information/YbgcProject";
// import { listYbgcTunnelAll } from "@/api/information/YbgcTunnel";
// import { monitoring } from "@/api/information/YbgcJdjk";

// import { getYbgcGdsc } from "@/api/information/YbgcGdsc";
// import Treeselect from "@riophae/vue-treeselect";
// import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import { listYbgcFormAll } from "@/api/information/YbgcGdscForm";
// import { listYbgcSgbwAll } from "@/api/information/YbgcSgbw";

// import {
//   // listYbgcSchedule,
//   getYbgcSchedule,
//   addYbgcSchedule,
//   updateYbgcSchedule,
// } from "@/api/information/YbgcSchedule";
// import { listYbgcTunnel } from "@/api/information/YbgcTunnel";

export default {
  name: "YbgcScsj",
  dicts: ["Ybgc_xdgx", "YbgcGdsc_type"],
  components: {},
  data() {
    return {
      name: "",
      YbgcJdjkId: "",
      value: [],
      long: 0,
      type: 1,
      YbgcScheduleList: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      xhs: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },

      // 表单参数
      form: {},
      form2: {},
      // 表单校验
      rules: {},
      useRole: false,
    };
  },
  created() {
    const YbgcJdjkId = this.$route.params && this.$route.params.id;
    this.YbgcJdjkId = YbgcJdjkId;
    if (YbgcJdjkId == 1) {
      this.name = "左线-进口端";
      this.getYbgcSchedule(14);
    }
    if (YbgcJdjkId == 2) {
      this.name = "右线-进口端";
      this.getYbgcSchedule(15);
    }
    if (YbgcJdjkId == 3) {
      this.name = "左线-出口端";
      this.getYbgcSchedule(14);
    }
    if (YbgcJdjkId == 4) {
      this.name = "右线-出口端";
      this.getYbgcSchedule(15);
    }
    // this.listYbgcTunnel();
    // for (var i in this.$store.state.user.user.roles) {
    //   if (
    //     this.$store.state.user.user.roles[i].roleName == "超级管理员" ||
    //     this.$store.state.user.user.roles[i].roleName == "进度管理" ||
    //     this.$store.state.user.user.roles[i].roleName == "项目经理" ||
    //     this.$store.state.user.user.roles[i].roleName == "项目总工" ||
    //     this.$store.state.user.user.roles[i].roleName == "项目副经理" ||
    //     this.$store.state.user.user.roles[i].roleName == "项目副总工" ||
    //     this.$store.state.user.user.roles[i].roleName == "工段长"
    //   ) {
    //     this.useRole = true;
    //     break;
    //   } else {
    //     this.useRole = false;
    //   }
    // }
    // this.monitoring();
    // this.monitoring2();
    // this.monitoring3();
    // this.listYbgcSchedule();
    // this.listYbgcProject();
    // this.listYbgcSgbwAll();
    // this.listYbgcSgbwAll2(1);
    // this.listYbgcFormAll();
  },
  onload(options) {
    console.log(options);
  },
  methods: {
    // listYbgcTunnel() {
    //   listYbgcTunnel({ pageNum: 1, pageSize: 10000 }).then((response) => {
    //     // this.YbgcTunnelList = response.rows;
    //     // this.form.tunnelId = response.rows[0].tunnelId;
    //     this.long = response.rows[0].tunnelWidth;
    //   });
    // },
    handleCommand(command) {
      console.log(command);
      this.YbgcJdjkId = command;
      if (command == 1) {
        this.name = "左线-进口端";
        this.getYbgcSchedule(14);
      }
      if (command == 2) {
        this.name = "右线-进口端";
        this.getYbgcSchedule(15);
      }
      if (command == 3) {
        this.name = "左线-出口端";
        this.getYbgcSchedule(14);
      }
      if (command == 4) {
        this.name = "右线-出口端";
        this.getYbgcSchedule(15);
      }
      // this.$router.push("/information/YbgcJdjk-data/YbgcJdjk/" + command);
    },
    getYbgcSchedule(id) {
      var that = this;
      this.$axios
        .get("https://blzhsdwb.qxweb.cloud/api/information/YbgcSchedule/getInfo/" + id)
        .then(function (response) {
          that.form = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.$axios({
      //   url: "/api/information/YbgcSchedule/getInfo/" + id,
      //   method: "get",
      //   success: (response) => {
      //     console.log(that.form);

      //   },
      //   error: () => {},
      // });
    },

    linkTo() {
      if (this.YbgcJdjkId == 1) {
        this.$router.push("/information/YbgcJdjk-data2/YbgcJdjk/14");
      }
      if (this.YbgcJdjkId == 2) {
        this.$router.push("/information/YbgcJdjk-data2/YbgcJdjk/15");
      }
      if (this.YbgcJdjkId == 3) {
        this.$router.push("/information/YbgcJdjk-data2/YbgcJdjk/14");
      }
      if (this.YbgcJdjkId == 4) {
        this.$router.push("/information/YbgcJdjk-data2/YbgcJdjk/15");
      }
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置$2a$10$Spra5y9ROqYfq5c2dH3EB.Ytmkam/3.M00ZkfFOrkjI515ZWU4Oaq
    reset() {
      this.form2 = {
        id: null,
        tunnelId: null,
        scheduleType: null,
        importExcavationProgress: null,
        importZzmNumber: null,
        exportExcavationProgress: null,
        exportZzmNumber: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        delFlag: null,
        leftYgzh: null,
        leftYgdk: null,
        rightYgzh: null,
        rightYgdk: null,
        leftEczh: null,
        leftEcdk: null,
        rightEczh: null,
        rightEcdk: null,
      };
      this.resetForm("form2");
    },

    /** 新增按钮操作 */
    // handleAdd(item) {
    //   this.reset();
    //   this.form.scheduleType = item;
    //   this.listYbgcTunnel();
    //   // this.getList2();
    //   this.open = true;
    //   this.title = "添加 隧道进度";
    // },
    /** 修改按钮操作 */
    handleUpdate() {
      this.reset();
      var id;
      if (this.YbgcJdjkId == 1) {
        id = 14;
      }
      if (this.YbgcJdjkId == 2) {
        id = 15;
      }
      if (this.YbgcJdjkId == 3) {
        id = 14;
      }
      if (this.YbgcJdjkId == 4) {
        id = 15;
      }

      getYbgcSchedule(id).then((response) => {
        this.form2 = response.data;
        this.open = true;
        this.title = "修改" + this.name + "隧道进度";
      });
    },
    /** 提交按钮 */
    submitForm() {
      console.log(this.form2);
      if (
        JSON.parse(this.form2.importExcavationProgress) +
          JSON.parse(this.form2.exportExcavationProgress) >
        this.long
      ) {
        this.$modal.msgWarning("进度与出口开挖进度的总和不能超过隧道长度！");
        return false;
      }
      if (JSON.parse(this.form2.importExcavationProgress) < 0) {
        this.$modal.msgWarning("进口开挖进度不能为负数！");
        return false;
      }
      if (JSON.parse(this.form2.exportExcavationProgress) < 0) {
        this.$modal.msgWarning("出口开挖进度不能为负数！");
        return false;
      }
      this.$refs["form2"].validate((valid) => {
        if (valid) {
          if (this.form2.id != null) {
            updateYbgcSchedule(this.form2).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getYbgcSchedule(this.form2.id);
              // this.getList();
              // this.$emit("child-event");
            });
          } else {
            addYbgcSchedule(this.form2).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
              // this.$emit("child-event");
            });
          }
        }
      });
    },

    // 多选框选中数据
  },
};
</script>
<style scope>
.btnEdit {
  position: absolute;
  top: 2%;
  right: 1%;
}
.title-name .el-dropdown-link {
  font-size: 24px;
  color: #000000;
  cursor: pointer;
}
.title-name {
  background: url("../assets/sdimg/backk.png") no-repeat;
  background-size: 100% 100%;
  width: 293.3px;
  height: 60.3px;
  position: absolute;
  top: 2%;
  left: 1%;
  font-size: 24px;
  line-height: 60.3px;
  text-align: center;
  color: #000000;
}
.back .line {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 24vw;
  height: 3vh;
  position: absolute;
  top: 81%;
  left: 18%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(-11.5deg);
}
.back .line p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3vh;
}
.back .line2 {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 59vw;
  height: 4.2vh;
  position: absolute;
  top: 88%;
  left: 19%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(-11.5deg);
}
.back .line2 p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3.5vh;
}
.back {
  background: url(../assets/sdimg/10.png) no-repeat;
  background-size: 100% 100%;
  width: 85vw;
  height: 50vh;
  margin: 0 auto;
}
.back .title_one {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: 12%;
  left: 5%;
}
.back .title_one p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back .title_two {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -5%;
  left: 47%;
}
.back .title_two p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back .title_three {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -20%;
  right: 1%;
}
.back .title_three p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
</style>
<style scope>
.back2 .line {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 24vw;
  height: 3vh;
  position: absolute;
  top: 59%;
  left: 9%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(7.5deg);
}
.back2 .line p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3vh;
}
.back2 .line2 {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 60vw;
  height: 4.2vh;
  position: absolute;
  top: 100%;
  left: 8%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(7.5deg);
}
.back2 .line2 p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3.5vh;
}
.back2 {
  background: url(../assets/sdimg/ckLeft.png) no-repeat;
  background-size: 100% 100%;
  width: 85vw;
  height: 36vh;
  margin: 0 auto;
}
.back2 .title_one {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -45%;
  left: 1%;
}
.back2 .title_one p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back2 .title_two {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -22%;
  left: 47%;
}
.back2 .title_two p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back2 .title_three {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -7%;
  right: -4%;
}
.back2 .title_three p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
</style>
<style scope>
.back3 .line {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 24vw;
  height: 3vh;
  position: absolute;
  top: 91%;
  right: 12%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(7.5deg);
  text-align: right;
}
.back3 .line p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3vh;
}
.back3 .line2 {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 59vw;
  height: 4.2vh;
  position: absolute;
  top: 100%;
  right: 12.4%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(7.5deg);
  text-align: right;
}
.back3 .line2 p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3.5vh;
}
.back3 {
  background: url(../assets/sdimg/jkLeft.png) no-repeat;
  background-size: 100% 100%;
  width: 85vw;
  height: 37vh;
  margin: 0 auto;
}
.back3 .title_one {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -40%;
  left: 9%;
}
.back3 .title_one p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back3 .title_two {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -26%;
  left: 39%;
}
.back3 .title_two p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back3 .title_three {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -7%;
  right: -4%;
}
.back3 .title_three p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
</style>
<style scope>
.back4 .line {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 24vw;
  height: 3vh;
  position: absolute;
  top: 47%;
  right: 5%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(-11.7deg);
  text-align: right;
}
.back4 .line p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3vh;
}
.back4 .line2 {
  background: url(../assets/sdimg/line.png) no-repeat;
  background-size: 100% 100%;
  width: 59vw;
  height: 4.2vh;
  position: absolute;
  top: 80%;
  right: 3.2%;
  z-index: 1;
  /* transform: rotate(168.5deg); */
  transform: rotate(-11.7deg);
  text-align: right;
}
.back4 .line2 p {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  font-size: 3vh;
  color: #000000;
  margin-top: 3.5vh;
}
.back4 {
  background: url(../assets/sdimg/jkRight.png) no-repeat;
  background-size: 100% 100%;
  width: 85vw;
  height: 47vh;
  margin: 0 auto;
}
.back4 .title_one {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: 15%;
  left: 0%;
}
.back4 .title_one p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back4 .title_two {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -8%;
  left: 39%;
}
.back4 .title_two p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.back4 .title_three {
  cursor: pointer;
  background: url("../assets/sdimg/title_back.png") no-repeat;
  background-size: 100% 100%;
  width: 18.75vw;
  height: 19.53vh;
  position: absolute;
  top: -33%;
  right: -4%;
}
.back4 .title_three p {
  height: 5.1vh;
  font-size: 2vh;
  padding-top: 1.2vh;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  text-align: center;
}
</style>
